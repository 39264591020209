.simplebar-scrollbar:before {
  background-color: #4567ae;
  left: 6px;
}
.freezeColumnHeading {
  width: 214px;
  display: flex;
  justify-content: flex-end;
}
.freezeColumnHeading.sticky {
  z-index: 999;
  right: 0;
  top: 0;
  position: sticky;
  background: #fff;
  text-align: center;
  width: 214px;
  box-sizing: border-box;
  border-left: 1px solid #cccccc8f;
}
.cardTableCeil {
  width: 214px;
  display: flex;
  justify-content: flex-end;
}
.cardTableCeil.sticky {
  z-index: 1;
  right: 0;
  top: 0;
  position: sticky;
  background: #fff;
  text-align: center;
  width: 214px;
  box-sizing: border-box;
  border-left: 1px solid #cccccc8f;
  justify-content: center;
}
.hideColumn {
  display: none!important;
  width: 0!important;
}
.hide {
  display: none!important;
}
.linkTextHover {
color: rgba(0, 0, 0, 0.87)
}
.linkTextHover:hover {
  color: #626DF9
}
.custom-pdf-page {
  margin: 3px 0px;
}
.custom-pdf-page .react-pdf__Page__canvas {
  margin: 0 auto;
}

#split-pdf-view .custom-pdf-page .react-pdf__Page__textContent {
  border: 1px solid rgb(128 128 128 / 50%);
}
.info-snackbar .MuiSnackbarContent-root {
  background-color: #0288d1!important;
}
.error-snackbar .MuiSnackbarContent-root {
  background-color: #d32f2f!important;
}
.info-snackbar .MuiSnackbarContent-root .MuiButtonBase-root{
  background: rgb(80 158 211)!important;
}
.accordionDetail.collapseIconSticky {
  position: fixed;
  top: 10%;
  right: 30px;
  background-color: #fff;
  border: 1px solid #819199;
  border-radius: 50px;
  height: 20px;
  width: 20px;
}
.accordionDetail1.collapseIconHidden {
  display: none;
}

.cardSubstanceDetail.collapseIconSticky {
  position: fixed;
  left: 95%;
  background-color: #fff;
  border: 1px solid #819199;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  bottom: 70px;
}

.customScrollbar::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 5px;
}

.customScrollbar::-webkit-scrollbar {
	width: 3px;
	background-color: #F5F5F5;
}

.customScrollbar::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-color: #626DF9;
}
