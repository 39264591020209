body {
  margin: 0;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  height: 100%;
}
html {
  height: 100%;
}

code {
  font-family: 'Montserrat', sans-serif;
}
@media print {
  body {
     -webkit-print-color-adjust: exact;
  }
}
